import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=26b2746c&"
import script from "./ProductCard.vue?vue&type=script&lang=ts&"
export * from "./ProductCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProductCard.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCardActions: require('/var/www/html/components/ProductCard/ProductCardActions.vue').default,ProductCardLabels: require('/var/www/html/components/ProductCard/ProductCardLabels.vue').default,ProductCardReviews: require('/var/www/html/components/ProductCard/ProductCardReviews.vue').default,ProductAvailable: require('/var/www/html/components/Product/ProductAvailable.vue').default,ProductCardVariations: require('/var/www/html/components/ProductCard/ProductCardVariations/ProductCardVariations.vue').default,ProductCardPrice: require('/var/www/html/components/ProductCard/ProductCardPrice.vue').default,ProductCntChooser: require('/var/www/html/components/Product/ProductCntChooser.vue').default})
