




































































































import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import {
  computed,
  defineComponent,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  useContext,
  watch,
} from '@nuxtjs/composition-api'
import { plainToClass } from 'class-transformer'

import { getGtagObjectFromProductFields } from '~/components/ProductCard/product-card-gtag'
import { ModalName } from '~/types/modal.enum'
import { useAccessor } from '~/utils/compositions/useAccessor'
import useModal from '~/utils/compositions/useModal'
import { AvailableStatus, ProductEntity } from '~/utils/models/product.entity'

import useProductCardFields from './product-card-fields'
import useResize from '~/utils/compositions/useResize'
import { Breakpoints } from '~/types/constants'

export default defineComponent({
  components: { CollapseTransition },
  props: {
    size: {
      type: String,
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
    showReviews: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object as () => ProductEntity,
      default: () => ({}),
    },
    multilineTitle: {
      type: Boolean,
      default: false,
    },
    hoverCollapse: {
      type: Boolean,
      default: true,
    },
    showChooseCnt: {
      type: Boolean,
      default: false,
    },
    isWishlist: {
      type: Boolean,
      default: false,
    },
    allowHoverResize: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { multilineTitle, item, hoverCollapse, size } = toRefs(props)
    const { app, $gtm } = useContext()

    const isMobile = ref(false)
    useResize((wWidth) => {
      isMobile.value = wWidth < Breakpoints.sm
    })

    const productLink = computed(() =>
      app.localePath({
        name: 'product',
        params: { slug: item.value.slug },
      }),
    )
    const product = ref(plainToClass(ProductEntity, item.value))

    watch(item, () => {
      product.value = plainToClass(ProductEntity, item.value)
    })

    const selfEl = ref<HTMLElement>(null as any as HTMLElement)

    const addToCart = async () => {
      await app.$accessor.cart.addItem({
        id: product.value.activeItem.id,
        cnt: 1,
        productId: product.value.id,
        fetchItems: true,
      })
    }

    // @ts-ignore
    const productFields = useProductCardFields(product)

    const {
      isFullImage,
      topSale,
      isPopular,
      name,
      hasSale,
      price,
      oldPrice,
      sale,
      availableStatus,
      image,
      variations,
      activeVariation,
      totalReviews,
      rating,
      hasVariations,
      isAvailable,
      categoriesTreeNames,
    } = productFields

    const allowCollapse = computed(
      () =>
        hasVariations.value &&
        availableStatus.value === AvailableStatus.available &&
        hoverCollapse.value &&
        size?.value !== 'sm',
    )

    const collapseTitle = computed(() => hoverCollapse.value && size?.value !== 'sm' && isHover.value === true)

    const isHover = ref(false)
    const hoverClass = ref(false)
    const itemClone = ref(null as any)
    const animatingState = ref('leave')

    const onMouseEnter = () => {
      isHover.value = true
      const el = selfEl.value

      if (props.allowHoverResize) {
        el.style.position = 'absolute'
        el.style.height = 'auto'
        el.style.top = '0'
        el.style.left = '0'
        el.style.right = '0'
        el.style.transition = 'height 1.5s ease-in-out'
        el.style.zIndex = '100'
      }
    }

    const afterHoverLeave = () => {
      if (animatingState.value === 'enter') return

      const el = selfEl.value

      if (el.parentNode === document.body) {
        el.style.left = ''
        el.style.top = ''
        el.style.width = ''
        el.style.position = ''

        itemClone.value.after(el)
        itemClone.value.remove()
        itemClone.value = null
      }
      animatingState.value = 'leave'
      // hoverClass.value = false
    }

    const beforeHoverEnter = () => {
      animatingState.value = 'enter'
    }

    const afterHoverEnter = () => {
      animatingState.value = 'enter'
    }

    const beforeHoverLeave = () => {
      animatingState.value = 'leave'
      // hoverClass.value = false
    }

    const onMouseLeave = () => {
      const el = selfEl.value

      // Todo: off for mobile
      if (props.allowHoverResize) {
        el.style.transition = ''
        el.style.position = ''
        el.style.zIndex = ''
        el.style.top = ''
        el.style.left = ''
        el.style.right = ''
      }

      isHover.value = false
      hoverClass.value = false
      if (!allowCollapse.value) {
        animatingState.value = 'leave'

        afterHoverLeave()
      }
    }

    onUnmounted(() => {
      if (selfEl.value) {
        selfEl.value.remove()
      }
      if (itemClone.value) {
        itemClone.value.remove()
      }
    })

    const activeCnt = ref(1)

    const openQuickView = (e: Event) => {
      e.stopPropagation()
      const { showByName } = useModal()
      showByName(ModalName.quickView, {
        props: {
          slug: product.value.slug,
        },
      })
      onGtagSelectItem()
    }

    const callWhenAvailable = () => {
      const { showByName } = useModal()
      showByName(ModalName.availableModal, {
        props: {
          productId: product.value.id,
        },
      })
    }

    const onGtagSelectItem = () => {
      $gtm.push({ ecommerce: null, event: 'clear' })
      $gtm.push({
        event: 'select_item',
        ecommerce: {
          items: [getGtagObjectFromProductFields(activeVariation.value || product.value.id, productFields)],
        },
      })
    }

    return {
      callWhenAvailable,
      isAvailable,
      activeCnt,
      afterHoverEnter,
      beforeHoverLeave,
      selfEl,
      beforeHoverEnter,
      afterHoverLeave,
      hoverClass,
      isHover,
      onMouseEnter,
      onMouseLeave,
      activeVariation,
      variations,
      availableStatus,
      image,
      isPopular,
      name,
      sale,
      price,
      hasSale,
      oldPrice,
      topSale,
      addToCart,
      productLink,
      isFullImage,
      openQuickView,
      totalReviews,
      rating,
      allowCollapse,
      onGtagSelectItem,
      collapseTitle,
    }
  },
})
